export const menuData = [
    {
        link: '/talents/all?gender=2',
        section: 'talents',
        text: 'Women'
    },
    {
        link: '/talents/all?gender=1',
        section: 'talents',
        text: 'Men'
    },
    //{
        //link: '/talents/all?gender=0',
        //section: 'talents',
        //text: 'Non-Binary'
    //},
    //{
        //link: '/talents/all?influencers=true',
        //section: 'talents',
        //text: 'Influencers'
    //},
    {
        link: '/talents',
        section: 'talents',
        text: 'All'
    },
]
