import "./home.scss";
import logoTransp from "../../assets/organic-logo-t.png"
import Slider from "../../components/slider/Slider";
import { menuData } from "../../configs/navBarMenu";
import {Link} from "react-router-dom"

import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';

const Home = () => {

    return (
        <div className="home">
            <Slider />
            <div className="sContentContainer">
                <div className="sContent">
                    <div className="logo">
                        <img src={logoTransp} alt="" className="logo" />
                    </div>
                    <div className="sMenuContainer">
                        {
                            menuData.map((menuItem, index) => (
                                <Link to={menuItem.link} key={index} className="menuItem">
                                    {menuItem.text}
                                </Link>
                            ))
                        }
  
                        <div className="contactUs">
                            <Link to="/contact" className="cIcon">
                                <PhoneEnabledOutlinedIcon />
                            </Link>
                            <a href="https://instagram.com/organictalentmanagement?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer" className="cIcon">
                                <InstagramIcon />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="sFooter">
                    <span>© Copyright {new Date().getFullYear()} | Privacy Policy | Powered by PATRICIO MANUEL</span>
                </div>
            </div>
        </div>
    )
}

export default Home
