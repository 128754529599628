import "./slider.scss"

// import imageMain from "../../images/home/image1.jpeg"
const Slider = () => {
    return (
        <div className="slider">
            <div className="load"></div>
            <div className="overlay"></div>
            
        </div>
    )
}

export default Slider;